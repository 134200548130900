<template>
  <div>
    <div class="user-management-container">
      <el-button @click="goBack" style="margin: 10px 10px 20px 10px;">返回</el-button>

      <vab-query-form class="queryForm">
        <vab-query-form-left-panel :span="24">
          <el-form :inline="true" :model="queryForm" @submit.prevent :label-position="labelPosition">
            <el-form-item label="状态" label-width="50px">
              <el-select v-model="queryForm.status" @change="queryData">
                <template v-for="(item, index) in userTypeList" :key="index">
                  <el-option :value="item.key" :label="item.name"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="留资时间">
              <el-date-picker v-model="createTime" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD" :shortcuts="shortcuts" />
            </el-form-item>
            <el-form-item label="姓名">
              <el-input type="text" placeholder="请输入" v-model="queryForm.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input type="text" placeholder="请输入" v-model="queryForm.phone"></el-input>
            </el-form-item>
            <!--  -->
           

            <el-form-item>
              <el-button type="primary" @click="queryData">
                查询
              </el-button>
              <el-button type="warning" @click="resetQueryData">
                置空
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-left-panel>
      </vab-query-form>

      <vab-query-form v-if="gxStatus  == 'PASS'">
        <vab-query-form-left-panel :span="24">
          <el-form :inline="true" :model="queryForm" @submit.prevent :label-position="labelPosition">
            <el-form-item label="批量操作" style="margin: 0 0 10px 0 !important" v-if="checkUserList.length > 0">
              <el-button type="primary" @click="dialogFormVisible2 = true" v-if="queryForm.status != 'PASS'">优选匹配</el-button>
              <el-button type="danger" @click="dialogFormVisible1 = true" v-if="queryForm.status != 'NO_PASS'">
                匹配失败
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-left-panel>
      </vab-query-form>

      <!-- -->
      <el-table v-loading="listLoading" border :data="list" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column align="center" label="供需类型" prop="name">
          <template #default="{ row }">
            {{ handleGxType(row) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="行业类型">
          <template #default="{ row }">
            {{ row.industry ? row.industry : '/' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="发布时间">
          <template #default="{ row }">
            {{ row.publishTime ? handleDate(row.publishTime) : '/' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="标题">
          <template #default="{ row }">
            <span @click="handleDetail(row)" style="color:#00c4c9;text-decoration: underline;cursor: pointer;">{{ row.title ? row.title : '/'  }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发布状态">
          <template #default="{ row }">
            <el-tag type="success" v-if="row.status == 'PENDING' || row.status == 'PASS'"> {{ handleStatus(row) }}</el-tag>
            <el-tag type="danger" v-else> {{ handleStatus(row) }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column align="center" label="留资时间">
          <template #default="{ row }">
            {{ handleDate(row.leaveInfoTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="姓名">
          <template #default="{ row }">
            {{ row.name ? row.name : '/' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="手机号">
          <template #default="{ row }">
            {{ row.phone ? row.phone : '/' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="匹配状态">
          <template #default="{ row }">
            <el-tag type="success" v-if="row.leaveInfoStatus == 'PENDING' || row.leaveInfoStatus == 'PASS'"> {{ handleStatusByLevel(row) }}</el-tag>
            <el-tag type="danger" v-else> {{ handleStatusByLevel(row) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" show-overflow-tooltip>
          <template #default="{ row }">
            <el-button @click="handleEdit(row)" type="primary">
              查看详情
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
      <el-pagination background :current-page="queryForm.pageNum" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      <edit ref="editRef" @fetch-data="fetchData" :meetingCode="queryForm.meetingCode" />
      <detail ref="detailRef"></detail>
    </div>

    <el-drawer title="填写驳回原因" ref="drawerRef" v-model="dialogFormVisible1" :size="size1" :append-to-body="true">
      <el-alert title="请确认匹配失败，并输入留言" type="error" effect="dark" />
      <el-form :model="form" :rules="rules" ref="reasonRef">
        <el-form-item label="留言" prop="reason"> 
          <el-input v-model="form.reason" type="textarea" maxlength="100" show-word-limit/>
        </el-form-item>
        <el-form-item align="center">
          <el-button @click="refuse" type="primary" size="large">提交</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <el-drawer title="填写通过原因" ref="drawerRef" v-model="dialogFormVisible2" :size="size1" :append-to-body="true">
      <el-alert title="请确认通过优选匹配" type="info" effect="dark" />
      <el-form :model="form" ref="reasonRef">
        <el-form-item label="留言" prop="reason">
          <el-input v-model="form.reason" type="textarea" maxlength="100" show-word-limit/>
        </el-form-item>
        <el-form-item align="center">
          <el-button @click="accept" type="primary" size="large">提交</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

  </div>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import {
  attendAuditList,
  getSdLeaveList,
  sdBatchAudit,
} from '@/api/exhibitionManage'
import { useRoute } from 'vue-router'
import { parseTime } from '@/utils/index'
export default defineComponent({
  name: 'UserManage',
  components: {
    Edit: defineAsyncComponent(() =>
      import('./components/matchingDetail1.vue')
    ),
    Detail: defineAsyncComponent(() =>
      import('./components/matchingDetail.vue')
    ),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      labelPosition: `center`,
      editRef: null,
      list: [],
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      showShop: false,
      counrtyList: [],
      keywod: '',
      checkUserList: [],
      reason: '拒绝你',
      form: {
        reason: '',
      },
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      size1: '30%',
      rules: {
        reason: [
          {
            required: true,
            message: '请填写驳回原因',
            trigger: 'blur',
          },
        ],
      },
      isSendMsg: true,
      queryForm: {
        endTime: '',
        industryId: '',
        meetingCode: '',
        name: '',
        pageNum: 1,
        pageSize: 10,
        phone: '',
        sdType: '',
        startTime: '',
        status: 'PENDING',
        title: '',
        userSdFormCode: '',
      },
      createTime: [],
      statusList: {
        PENDING_REVIEW: '待审核',
        ACCEPT: `审核通过`,
        REFUSE: '审核不通过',
        CANCEL_SIGN: `取消报名`,
        NOT_REVIEW: `无需审核`,
      },
      userTypeList: [
        {
          name: '待匹配',
          key: 'PENDING',
        },
        {
          name: '优选匹配',
          key: 'PASS',
        },
        {
          name: '匹配失败',
          key: 'NO_PASS',
        },
      ],
      userTypeList1: [
        {
          name: '待审核',
          key: 'PENDING',
        },
        {
          name: '已发布',
          key: 'PASS',
        },
        {
          name: '已取消',
          key: 'CANCEL',
        },
        {
          name: '审核不通过',
          key: 'NO_PASS',
        },
      ],

      gxList: [
        {
          name: '全部',
          key: '',
        },
        {
          name: '需求',
          key: 'DEMAND',
        },
        {
          name: '供应',
          key: 'SUPPLY',
        },
      ],
      industyList: [
        {
          name: '全部',
          key: '',
        },
      ],
      officalCountList: [],
      reasonRef: null,
      detailRef: null,
      gxStatus: '',
    })

    const setSelectRows = (val) => {
      state.selectRows = val
    }
    const handleEdit = (row) => {
      state['editRef'].handleOpen(row, state.queryForm.meetingCode)
    }
    const handleDelete = (row) => {
      if (row.uid) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteUser(row.uid)
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }
    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      fetchData()
    }

    const route = useRoute()

    const queryData = () => {
      if (state.createTime && state.createTime.length > 0) {
        console.log(state.createTime)
        state.queryForm.startTime = parseTime(
          state.createTime[0],
          '{y}-{m}-{d}'
        )
        state.queryForm.endTime = parseTime(state.createTime[1], '{y}-{m}-{d}')
      } else {
        state.queryForm.startTime = ''
        state.queryForm.endTime = ''
      }
      fetchData()
    }
    const handleQueryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getSdLeaveList(state.queryForm)
      state.listLoading = false
      if (data == null) {
        state.list = []
        state.total = 0
        return false
      }
      state.list = data.data
      state.total = data.total
    }
    onMounted(async () => {
      state.queryForm.meetingCode = route.query.meetingCode
      state.queryForm.userSdFormCode = route.query.userSdFormCode
      state.gxStatus = route.query.status
      fetchData()
    })

    const selectChange = (type, key) => {
      if (type == 'userType') {
        state.queryForm.auditStatus = key
      }
      if (type == 'officalCount') {
        state.queryForm.identity = key
      }
      if (type == 'isExistAuditPassForum') {
        state.queryForm.isExistAuditPassForum = key
      }
      fetchData()
    }

    const selectChangeBySwitch = () => {
      fetchData()
    }
    const handleSelectionChange = (e) => {
      console.log(e)
      state.checkUserList = e
    }

    const handleDate = (val) => {
      return parseTime(val)
    }

    const goBack = () => {
      proxy.$router.go(-1)
    }

    const refuse = async () => {
      state['reasonRef'].validate(async (valid) => {
        if (valid) {
          const audienceUid = state.checkUserList.map((v) => v.leaveInfoCode)
          try {
            let parmas = {
              reason: state.form.reason,
              status: 'NO_PASS',
              codes: audienceUid,
              type: 'LEAVE_INFO_FORM',
              meetingCode: state.queryForm.meetingCode,
            }
            const { data } = await sdBatchAudit(parmas)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          } catch (err) {
            console.log(err)
            proxy.$baseMessage(`操作失败`, 'error', 'vab-hey-message-error')
          }
          queryData()
          state.dialogFormVisible1 = false
          state.checkUserList = []
        }
      })
    }
    const accept = async () => {
      const audienceUid = state.checkUserList.map((v) => v.leaveInfoCode)
      try {
        let parmas = {
          reason: state.form.reason,
          status: 'PASS',
          codes: audienceUid,
          type: 'LEAVE_INFO_FORM',
          meetingCode: state.queryForm.meetingCode,
        }
        const { data } = await sdBatchAudit(parmas)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      } catch (err) {
        proxy.$baseMessage(`操作失败`, 'error', 'vab-hey-message-error')
      }
      queryData()
      state.dialogFormVisible2 = false
      state.form.reason = ''
      state.checkUserList = []
    }

    const handleGxType = (row) => {
      let checkItem = state.gxList.filter((v) => v.key == row.sdType)
      if (checkItem.length > 0) {
        return checkItem[0].name
      } else {
        return '暂无数据'
      }
    }

    const handleStatus = (row) => {
      let checkItem = state.userTypeList1.filter((v) => v.key == row.status)
      if (checkItem.length > 0) {
        return checkItem[0].name
      } else {
        return '暂无数据'
      }
    }

    const handleStatusByLevel = (row) => {
      let checkItem = state.userTypeList.filter(
        (v) => v.key == row.leaveInfoStatus
      )
      if (checkItem.length > 0) {
        return checkItem[0].name
      } else {
        return '暂无数据'
      }
    }

    const resetQueryData = () => {
      state.queryForm = {
        endTime: '',
        industryId: '',
        meetingCode: route.query.meetingCode,
        name: '',
        pageNum: 1,
        pageSize: 10,
        phone: '',
        sdType: '',
        startTime: '',
        status: 'PENDING',
        title: '',
        userSdFormCode: route.query.userSdFormCode,
      }
      state.createTime = []
      fetchData()
    }
    const handleDetail = (row) => {
      state['detailRef'].handleOpen(row, state.queryForm.meetingCode, 'see')
    }
    return {
      ...toRefs(state),
      setSelectRows,
      selectChangeBySwitch,
      handleEdit,
      handleDelete,
      handleSizeChange,
      handleCurrentChange,
      queryData,
      fetchData,
      selectChange,
      handleSelectionChange,
      handleDate,
      goBack,
      accept,
      refuse,
      handleGxType,
      resetQueryData,
      handleStatus,
      handleDetail,
      handleStatusByLevel,
    }
  },
})
</script>


<style lang="scss" scoped>
.radioBox {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-wrap: wrap;
  li {
    list-style: none;
    margin-right: 20px;
    cursor: pointer;
    &.isChecked {
      color: #00c4c9;
    }
  }
}
</style>