<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog v-model="dialogFormVisible" title="选择机构" width="60%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-radio-group v-model="enterPrise">
      <el-radio border :label="item.enterpriseCode" v-for="(item, index) in list" :key="index" style="margin-bottom: 10px">
        {{ item.name }}
      </el-radio>
    </el-radio-group>

    <template #footer>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { getStaffEnterpriseList } from '@/api/enterPriseApi'

export default defineComponent({
  name: 'chooseEnterprise',
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      formRef: null,
      treeRef: null,
      checkMenu: [],
      pageList: [],
      statusArr: [
        {
          label: '审核通过',
          value: 'AUDIT_APPROVED',
          type: 'success',
        },
        {
          label: '待审核',
          value: 'PENDING_AUDIT',
        },
        {
          label: '审核驳回',
          value: 'REJECTED',
          type: 'danger',
        },
      ],
      form: {},
      rules: {
        status: [
          { required: true, trigger: 'change', message: '请选择审核状态' },
        ],
        reason: [
          { required: true, trigger: 'blur', message: '请输入驳回原因' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      list: [],
      enterPrise: null,
    })

    const open = async (row) => {
      const { data } = await getStaffEnterpriseList()
      state.list = data
      state.dialogFormVisible = true
    }

    const close = () => {
      state.dialogFormVisible = false
    }

    const handleCurrentChange = (val) => {
      console.log(val)
    }

    const save = () => {
      if (state.enterPrise) {
        let data = state.list.filter(
          (v) => v.enterpriseCode == state.enterPrise
        )
        console.log(data, '====>>>>>')
        if (data[0].member == 'BASE') {
          proxy.$baseMessage(
            `非VIP机构会员的机构无法使用PC端功能，您可以升级为VIP，解锁PC端管理权限`,
            'error',
            'vab-hey-message-error'
          )
          return false
        }
        localStorage.setItem('isEnterpriseItem', JSON.stringify(data[0]))
        proxy.$pub('changeEnterprise', data[0])
        close()
      }
    }

    return {
      ...toRefs(state),
      open,
      close,
      save,
      handleCurrentChange,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.demo-drawer__content {
  height: 85vh;
  overflow: auto;
}
.demo-drawer__footer {
  position: fixed;
  right: 20px;
  bottom: 10px;
}
</style>
