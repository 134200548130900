import request from '@/utils/request'

/**
 * @description: 查询企业类型
 * @param {*} data
 * @return {*}
 */
export function getAllEnterPriseTypeList(param) {
  return request({
    url: '/user/enterprise/getTypeList',
    method: 'GET',
    param,
  })
}

/**
 * @description: 查询入驻企业列表
 * @param {*} params
 * @return {*}
 */
export function getAllEnterPriseList(params) {
  return request({
    url: '/user/admin/enterprise/query',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询机构入驻列表
 * @return {*}
 */
export function getAllEnterPriseListByOrg(params) {
  return request({
    url: '/user/admin/enterprise/queryList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 机构入驻详情
 * @return {*}
 */

export function enterpriseQueryDetail(enterpriseCode, params) {
  return request({
    url: `/user/admin/enterprise/queryDetail/${enterpriseCode}`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 企业入驻审核
 * @param {*} data
 * @return {*}
 */
export function auditEnterPrise(data) {
  return request({
    url: '/user/admin/enterprise/audit',
    method: 'POST',
    data,
  })
}
/**
 * @description: 删除企业
 * @param {*} data
 * @return {*}
 */
export function delEnterPrise(params) {
  return request({
    url: '/user/admin/enterprise/delEnterprise',
    method: 'DELETE',
    params,
  })
}

/**
 * @description: 查询企业提交订单
 * @param {*} data
 * @return {*}
 */
export function queryOrderEnterprise(params) {
  return request({
    url: '/user/admin/enterprise/order/query',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询订单详情
 * @return {*}
 */
export function queryOrderEnterpriseDetail(orderNo, params) {
  return request({
    url: `/user/admin/enterprise/order/queryDetail/${orderNo}`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 审核支付状态
 * @param {*} data
 * @return {*}
 */
export function updatePayStatus(data) {
  return request({
    url: '/user/admin/enterprise/order/updatePayStatus',
    method: 'POST',
    data,
  })
}

/**
 * @description: 更新到期时间
 * @param {*} data
 * @return {*}
 */
export function updateExpireTime(data) {
  return request({
    url: '/user/admin/enterprise/order/updateExpireTime',
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询更新到期时间记录
 * @param {*} data
 * @return {*}
 */
export function getOrderExpireTimeHistory(data) {
  return request({
    url: '/user/admin/enterprise/order/getOrderExpireTimeHistory',
    method: 'POST',
    data,
  })
}

export function getStaffEnterpriseList() {
  return request({
    url: '/user/admin/enterprise/getStaffEnterpriseList',
    method: 'GET',
  })
}

/**
 * @description: 获取企业用户来源
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryUserSource(params) {
  return request({
    url: '/user/admin/enterprise/management/queryUserSource',
    method: 'GET',
    params,
  })
}

/**
 * @description: 获取企业用户来源
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryUser(data) {
  return request({
    url: '/user/admin/enterprise/management/query/user',
    method: 'POST',
    data,
  })
}
/**
 * @description: 查询企业用户详情
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryUserDetail(params) {
  return request({
    url: '/user/admin/enterprise/management/query/userDetail',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询微信公众号配置
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryWechatConfig(params) {
  return request({
    url: '/user/admin/enterprise/management/query/wechat/config',
    method: 'GET',
    params,
  })
}

/**
 * @description: 录入微信公众号配置
 * @param {*} data
 * @return {*}
 */
export function enterPriseUserSaveWechatConfig(data) {
  return request({
    url: '/user/admin/enterprise/management/save/wechat/config',
    method: 'POST',
    data,
  })
}
/**
 * @description: 更新微信公众号配置
 * @param {*} data
 * @return {*}
 */
export function enterPriseUserUpdateWechatConfig(data) {
  return request({
    url: '/user/admin/enterprise/management/update/wechat/config',
    method: 'POST',
    data,
  })
}

/**
 * @description: 同步公众号粉丝
 * @param {*} data
 * @return {*}
 */
export function enterPriseUserSaveWxUser(data) {
  return request({
    url: '/user/admin/enterprise/management/saveWxUser',
    method: 'POST',
    data,
  })
}

export function enterPriseUserQueryUserDetailAttend(params) {
  return request({
    url: '/user/admin/enterprise/management/query/userDetail/attend',
    method: 'GET',
    params,
  })
}

export function enterPriseUserUpdateRemark(params) {
  return request({
    url: '/user/admin/enterprise/management/update/remark',
    method: 'GET',
    params,
  })
}

/**
 * @description: 通过名称查询机构列表
 * @return {*}
 */
export function getAllEnterpriseListByName(params) {
  return request({
    url: '/user/admin/enterprise/getEnterpriseList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询机构入驻渠道审核列表
 * @return {*}
 */
export function getChannelList(params) {
  return request({
    url: '/user/admin/enterprise/management/channel/query',
    method: 'GET',
    params,
  })
}

export function queryMemberLicense(params) {
  return request({
    url: '/user/enterprise/queryMemberLicense',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询机构列表
 * @return {*}
 */

export function enterpriseGroupQueryList(params) {
  return request({
    url: '/user/admin/enterprise/group/queryList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 新增机构列表
 * @return {*}
 */

export function addEnterpriseGroup(data) {
  return request({
    url: '/user/admin/enterprise/group/addEnterpriseGroup',
    method: 'POST',
    data,
  })
}

/**
 * @description: 修改机构列表
 * @return {*}
 */
export function upDateEnterpriseGroup(data) {
  return request({
    url: '/user/admin/enterprise/group/updateEnterpriseGroup',
    method: 'POST',
    data,
  })
}

/**
 * @description: 复制机构
 * @return {*}
 */

export function groupCopy(params) {
  return request({
    url: '/user/admin/enterprise/group/copy',
    method: 'GET',
    params,
  })
}

/**
 * @description: 删除机构列表
 * @return {*}
 */

export function delEnterpriseGroup(id) {
  return request({
    url: '/user/admin/enterprise/group/delEnterpriseGroup/' + id,
    method: 'POST',
  })
}

/**
 * @description: 查询机构类目组
 * @return {*}
 */
export function queryEnterpriseCategoryGroup(params) {
  return request({
    url: '/user/admin/enterprise/group/queryEnterpriseCategoryGroup',
    method: 'GET',
    params,
  })
}

/**
 * @description: 新增机构类目组
 * @return {*}
 */

export function addEnterpriseCategoryGroup(params) {
  return request({
    url: '/user/admin/enterprise/group/addEnterpriseCategoryGroup',
    method: 'GET',
    params,
  })
}

/**
 * @description: 删除机构类目组
 * @return {*}
 */
export function delEnterpriseCategoryGroup(id) {
  return request({
    url: '/user/admin/enterprise/group/delEnterpriseCategoryGroup/' + id,
    method: 'GET',
  })
}

/**
 * @description: 修改机构类目组
 * @return {*}
 */

export function upDateEnterpriseCategoryGroup(params) {
  return request({
    url: '/user/admin/enterprise/group/updateEnterpriseCategoryGroup',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询企业类目
 * @return {*}
 */

export function queryEnterpriseCategory(params) {
  return request({
    url: '/user/admin/enterprise/group/queryEnterpriseCategory',
    method: 'GET',
    params,
  })
}

/**
 * @description: 新增企业类目
 * @return {*}
 */
export function addEnterpriseCategory(params) {
  return request({
    url: '/user/admin/enterprise/group/addEnterpriseCategory',
    method: 'GET',
    params,
  })
}

/**
 * @description: 删除企业类目
 * @return {*}
 */
export function delEnterpriseCategory(id) {
  return request({
    url: '/user/admin/enterprise/group/delEnterpriseCategory/' + id,
    method: 'GET',
  })
}

/**
 * @description: 查询机构搜索配置
 * @return {*}
 */

export function querySearchConfig(params) {
  return request({
    url: '/user/admin/enterprise/group/querySearchConfig',
    method: 'GET',
    params,
  })
}

/**
 * @description: 设置机构搜素配置
 * @return {*}
 */
export function addOrUpdateSearchConfig(params) {
  return request({
    url: '/user/admin/enterprise/group/addOrUpdateSearchConfig',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询所有展商列表
 * @return {*}
 */
export function queryAllExhibitor(params) {
  return request({
    url: '/user/admin/enterprise/group/queryAllExhibitor',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询机构信息列表
 * @return {*}
 */

export function queryExhibitorList(params) {
  return request({
    url: '/user/admin/enterprise/group/queryExhibitorList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 添加机构信息
 * @param {*} data
 * @return {*}
 */
export function addGroupExhibitor(data) {
  return request({
    url: '/user/admin/enterprise/group/addGroupExhibitor',
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询配置的类目配置列表
 * @return {*}
 */

export function queryCategoryConfigList(params) {
  return request({
    url: '/user/admin/enterprise/group/queryCategoryConfigList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 新增机构类目配置
 * @return {*}
 */

export function addOrUpdateExhibitorCategory(data) {
  return request({
    url: '/user/admin/enterprise/group/addOrUpdateExhibitorCategory',
    method: 'POST',
    data,
  })
}

/**
 * @description: 已设置的机构类目配置回显
 * @return {*}
 */

export function queryExhibitorCategory(params) {
  return request({
    url: '/user/admin/enterprise/group/queryExhibitorCategory',
    method: 'GET',
    params,
  })
}

/**
 * @description: 设置机构信息排序
 * @return {*}
 */

export function setSort(params) {
  return request({
    url: '/user/admin/enterprise/group/setSort',
    method: 'GET',
    params,
  })
}

/**
 * @description: 设置机构信息状态
 * @return {*}
 */

export function setStatus(params) {
  return request({
    url: '/user/admin/enterprise/group/setStatus',
    method: 'GET',
    params,
  })
}

/**
 * @description: 删除机构信息
 * @return {*}
 */
export function delMeetingEnterprise(params) {
  return request({
    url: '/user/admin/enterprise/group/delMeetingEnterprise',
    method: 'GET',
    params,
  })
}

/**
 * @description: 类目分组批量添加展商
 * @return {*}
 */

export function batchAddExhibitor(data) {
  return request({
    url: '/user/admin/enterprise/group/batchAddExhibitor',
    method: 'POST',
    data,
  })
}

/**
 * @description: 类目分组批量查询展商
 * @return {*}
 */

export function queryExhibitorByCategoryId(params) {
  return request({
    url: '/user/admin/enterprise/group/queryExhibitorByCategoryId',
    method: 'GET',
    params,
  })
}
